<template>
  <div class="staff_management">
    <div class="query_condition">
      <!-- <div class="query_item">
        <span class="query_item_title">所属公司</span>
        <el-select v-model="belongCompanyName" filterable placeholder="请选择" style="width: 100%;" @change="doSelectBelongCompanyName" class="belonged_company_list">
          <el-option
              v-for="item in belongedCompanyList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div> -->
      <div class="query_item">
        <!-- <span class="query_item_title">员工姓名</span> -->
        <el-input placeholder="请输入用户账号姓名" v-model="name" maxlength="25" >
          <i class="iconfont iconicon1-30" slot="suffix" ></i>
        </el-input>
      </div>
      <div class="query_item">
        <el-button type="primary"  style="margin-right: 20px;" @click="handleQuery" v-auth='"subAccount:lookup"'>查询</el-button>
      </div>
      <div class="query_item" style="float: right;margin-right: 0;">
        <el-button type="primary"  style="margin-right: 20px;" @click="doAdd()" ><i class="iconfont iconicon-22" v-auth='"subAccount:add"'></i>新增</el-button>
        <el-button type="primary"  style="margin-right: 20px;" @click="doDel()" :loading="delLoading" v-auth='"subAccount:delete"'>删除</el-button>
        <el-button class="el-button el-button--primary el-button--medium is-plain" style="width: 120px" @click="doReset()" v-auth='"subAccount:reset"'>重置密码</el-button>
      </div>
    </div>
  <div class="table"  v-loading="isLoading">
    <div class="filter-container">
      <books-table :tableData="tableData" ref="staffTable"/>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
          class="pagination">
      </el-pagination>
    </div>
  </div>

  </div>
</template>

<script>
import BooksTable from './modules/booksTable'
export default {
  components: { BooksTable },
  data() {
    return {
      belongCompanyName: '',
      belongedCompanyList: [],
      name:'',

      tableData: [],
      pageSize: 20,
      totalCount:0,
      currentPage:1,
      isLoading:false,
      delLoading:false
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize=val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage=val;
      this.getList();
    },
    doAdd(){
      this.$router.push("/permission/subAccount/add");
    },
    del(){
      var ids =''
      this.$refs.staffTable.multipleSelection.forEach(e=>{
        ids += e.id+','
      })
      if(ids.indexOf(this.getToken("userId"))>-1){
        einvAlert.warning("提示","不允许删除本人账号")
        return;
      }
      this.delLoading = true
      this.$store.dispatch("DeleteAccount", {
        ids: ids
      }).then(res => {
        this.delLoading = false
        if (res.success) {
          this.currentPage=1;
          this.getList();
          einvAlert.success('提示',res.msg)
        } else {
          einvAlert.error('提示',res.msg)
        }
      }).catch(err => {
        this.isLoading = false
      })
    },
    doDel(){
      let _this=this;
      if(_this.$refs.staffTable.multipleSelection.length==0){
        einvAlert.warning("提示","请至少勾选一条记录");
        return;
      }

      einvAlert.confirm(function(){
        _this.del();
      },"确定要删除吗","确定","取消")
    },
    reset(){
      this.isLoading = true
      var ids =''
      this.$refs.staffTable.multipleSelection.forEach(e=>{
        ids += e.id+','
      })
      this.$store.dispatch("ResetPwdAccount", {
        ids: ids
      }).then(res => {
        this.isLoading = false
        if (res.success) {
          einvAlert.success('提示',res.msg)
        } else {
          einvAlert.error('提示',res.msg)
        }
      }).catch(err => {
        this.isLoading = false
      })
    },
    doReset(){
      let _this=this;
      if(_this.$refs.staffTable.multipleSelection.length==0){
        einvAlert.warning("提示","请至少勾选一条记录");
        return;
      }
      einvAlert.confirm(function(){
        _this.reset();
      },"登入密码将重置为初始密码","确定","取消")
    },
    getList() {
      this.isLoading = true
      this.$store.dispatch("FindAccount", {
        // agencyCompanyId: this.getToken('agencyCompanyId'),
        // belongCompanyName: this.belongCompanyName,
        query: this.name,
        page: this.currentPage,
        rows: this.pageSize,
      }).then(res => {
        this.isLoading = false
        if (res.success) {
          this.tableData = res.data;
          this.totalCount=res.totalCount;
        } else {
          einvAlert.error('提示',res.msg)
        }
      }).catch(err => {
        this.isLoading = false
      })
    },
    handleQuery(){
      this.page = 1;
      this.getList();
    },
  },
  mounted() {
    // this.getCompanyList();
    this.getList();
  },
}
</script>

<style lang='less' scoped>
.staff_management{
  width: 100%;
  height: 94%;
  .query_condition{
    width: 100%;
    padding: 0 20px ;
    background: #fff;
    margin-bottom: 20px ;
    .query_item{
      display: inline-block;
      margin: 10px 20px 10px 0px;
      .query_item_title{
        font-size: 16px;
        color: #333333;
        margin-right: 20px;
      }
      .el-date-editor--daterange{
        width: 400px !important;
      }
      .el-input{
        display: inline-block;
        width: 200px;
      }
      .belonged_company_list{
        width: 200px !important;
      }
      .iconicon-22{
        font-size: 12px;
        margin-right: 8px;
      }
    }
  }
  .table{
    width: 100%;
    height: 90% !important;
    padding: 1% 1% 2% 1%;
    background: #fff;
  }
  .pagination{
    margin-top: 20px;
    text-align: right;
    margin-right: 20px;
  }

}

.filter-container {
  padding: 1.04rem 0;
  height: calc(100% - 10px);
}
</style>
